import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
var logo = require('../assets/images/RCIC_logo.jpg')

const Footer = (props) => {
    const data = useStaticQuery(graphql`
        query {
            site{
                siteMetadata{
                    facebookURL,
                    instagramURL
                }
            }
        }
    `)

    return(
        <footer id="footer">
            <h4>خدمات مهاجرتی فرزان</h4>
            <section>
            <article>
                <p>فرزان سجودی، مشاور رسمی مهاجرت به کانادا و
                عضو <span><a href="https://iccrc-crcic.ca/">ICCRC</a> , <a href="https://www.capic.ca/">CAPIC</a></span></p>
                <a href="https://iccrc-crcic.ca/"><img src={logo} alt="rcic-logo" /></a>
            </article>
            <article>
                <h6>ارتباط با ما</h6>
                <ul>
                    <li className="space">۱&nbsp;۶۰۴۸۱۶۶۲۵۹ - ۱&nbsp;۶۷۲۵۱۵۱۰۱۰</li>
                    <li className="space">info@farzanimmigration.ca</li>
                    <li>ونکوور - کانادا</li>
                </ul>
                <ul className="icons">
                    <li><a href="https://www.facebook.com/search/top?q=Farzan%20Immigration%20Services" className="icon alt fa-facebook"><span className="label">Facebook</span></a></li>
                    <li><a href="https://www.instagram.com/farzanimmigrationservices/" className="icon alt fa-instagram"><span className="label">Instagram</span></a></li>
                </ul>
            </article>
            </section>
            <ul className="copyright">
                <li> کلیه حقوق مادی و معنوی این وب سایت برای موسسه مهاجرتی فرزان محفوظ است </li>
            </ul>
        </footer>
    )
}

export default Footer

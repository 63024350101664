import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Header = (props) => (
    <header id="header" className="alt" >
        <Link to="/" className="logo"><strong>فرزان</strong> <span>خدمات مهاجرتی</span></Link>
        
        <nav>
            <a className="menu-link" onClick={props.onToggleMenu}></a>
            <ul className="links">
                <li><Link to="/">خانه</Link></li>
                <li><Link to="/entrepreneur">خدمات</Link></li>
                <li><Link to="/#blog">اخبار</Link></li>
                <li><Link to={`/#footer`}>تماس ما</Link></li>
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
    onToggleMenu: PropTypes.func,
}


export default Header
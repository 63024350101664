import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Menu = (props) => (
    <nav id="menu">
        <div className="inner">
            <ul className="links">
                <li><Link onClick={props.onToggleMenu} to="/">خانه</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/immigrationForm">فرم ارزیابی مهاجرت</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/entrepreneur">کارآفرینی</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/skillworker">  نیروی کار ماهر</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/selfemployed">خوداشتغالی</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/sponsorship">  اسپانسرشیپ</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/studentvisa">ویزای تحصیلی</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/visitorvisa">  ویزای توریستی </Link></li>
                <li><Link onClick={props.onToggleMenu} to="/#blog">   اخبار </Link></li>
                <li><Link onClick={props.onToggleMenu} to={`/#footer`}>تماس ما</Link></li>
            </ul>
        </div>
        <a className="close" onClick={props.onToggleMenu}>Close</a>
    </nav>
)

Menu.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Menu